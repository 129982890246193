<template>
  <div id="action-condition">
    <b-row>
      <b-col
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
      >
        <b-button
          class="button-testing buttom-add w-100"
          variant="outline-primary"
          :disabled="!statusDisabled"
          @click="showModalCondition"
        >
          Tự công bố đủ điều kiện huấn luyện hạng A
        </b-button>
        <b-button
          class="button-testing buttom-add"
          variant="outline-primary"
          :disabled="statusDisabled"
          @click="getNotify"
        >
          Giấy thông báo đủ điều kiện huấn luyện hạng A
        </b-button>
        <b-row>
          <b-col>
            <b-button
              variant="danger"
              class="buttom-add button-delete"
              :disabled="statusDisabled"
              @click="hanldeDelete"
            >
              Xóa
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
      >
        <b-row>
          <span class="title-text">Thông tin giấy phép</span>
        </b-row>

        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày công bố</span>
          </b-col>
          <b-col
            v-if="!statusDisabled"
          >
            <span class="align-middle">{{ dataTraining.dateSelfClare | formatDateToDDMM }}</span>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <modal-create-condition
      :id="modalIdCondition"
      @accept="handleCreate($event)"
    />

    <modal-pdf
      :id="modalPdfId"
      :pdfsrc="pdfsrc"
      @downloadFilePdf="downloadFilePdf"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BButton, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import ModalCreateCondition from './modal-create/ModalCreateCondition.vue'
import ModalPdf from './ModalSeePdf.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BBadge,
    ModalCreateCondition,
    ModalPdf,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    statusDisabled: {
      type: Boolean,
      default: false,
    },

    dataTraining: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      modalIdCondition: 'modal-id-condition',
      modalPdfId: 'modal-pdf-id-condition',
      pdfsrc: null,
    }
  },

  methods: {
    showModalCondition() {
      this.$bvModal.show(this.modalIdCondition)
    },

    async handleCreate(param) {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_DECLARE_TRAINING, { ...param }).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm ngày công bố thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$emit('reload')
          this.$bvModal.hide(this.modalIdCondition)
        }
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async getNotify() {
      axiosApiInstance({
        url: ConstantsApi.CREATE_FILE_SELF_DECLARE_TRAINING,
        method: 'GET',
        responseType: 'blob',
        params: { selfDeclareTrainingId: this.dataTraining.id },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalPdfId)
    },

    downloadFilePdf() {
      axiosApiInstance({
        url: ConstantsApi.CREATE_FILE_SELF_DECLARE_TRAINING,
        method: 'GET',
        responseType: 'blob',
        params: { selfDeclareTrainingId: this.dataTraining.id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'GiayThongBaoDuDieuKienHuanLuyenHangA.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    hanldeDelete() {
      this.$emit('hanldeDelete')
    },
  },
}
</script>

<style lang="scss">
#action-condition {
  .button-testing {
    min-width: 100%;
    margin-bottom: 1rem;
  }
  .buttom-add {
    // height: 42px;
    button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .button-action {
    min-width: 100%;
  }
  .button-delete {
    height: 40px;
    min-width: 100%;
  }
  .title-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  .border-status {
    border-radius: 4px;
  }
}
</style>

export default {

  // TỰ CÔNG BỐ HUẤN LUYỆN
  CREATE_DECLARE_TRAINING: 'SelfDeclareTraining/selfDeclareTrainings',
  DELETE_DECLARE_TRAINING: 'SelfDeclareTraining/delete-self-declare-trainings',
  DETAIL_DECLARE_TRAINING: 'SelfDeclareTraining/selfDeclareTrainings/',
  GET_INFO_DECLARE_TRAINING: 'SelfDeclareTraining/self-declare-training-by-bussiness',
  API_LIST_DECLARE_TRAINING: 'SelfDeclareTraining/list-self-declare-training',
  CREATE_FILE_SELF_DECLARE_TRAINING: 'SelfDeclareTraining/file-self-declare-training',

  // CHƯƠNG TRÌNH KHUNG HUẤN LUYỆN NHÓM 5
  CREATE_PROGRAM: 'SelfDeclareTraining/add-program',
  EDIT_PROGRAM: 'SelfDeclareTraining/update-program',
  DELETE_PROGRAM: 'SelfDeclareTraining/delete-program',
  DETAIL_PROGRAM: 'SelfDeclareTraining/program/',
  API_PROGRAM: 'SelfDeclareTraining/list-programs',
}

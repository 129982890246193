<template>
  <b-modal
    :id="id"
    size="sm"
    title="Thêm ngày công bố"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="basicInput">Ngày công bố<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="dateSelfClare"
            >
              <date-time-picker
                v-model="dataInput.dateSelfClare"
                :disabledInput="true"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dateSelfClare: {
        required: 'Chọn ngày công bố là bắt buộc',
      },
      dataInput: {
        dateSelfClare: '',
      },
    }
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

    resetData() {
      this.dataInput = {}
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>

<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm đối tượng kiểm định"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateObject"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group>
        <label>Số thứ tự<span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="index"
        >
          <b-form-input
            v-model="dataInput.index"
            :state="errors.length > 0 ? false:null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <label>Nội dung huấn luyện<span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="content"
        >
          <b-form-textarea
            v-model="dataInput.content"
            :state="errors.length > 0 ? false:null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <label>Lý thuyết</label>
        <b-form-input
          v-model="dataInput.theory"
          type="number"
        />
      </b-form-group>

      <b-form-group>
        <label>Thực hành</label>
        <b-form-input
          v-model="dataInput.practice"
          type="number"
        />
      </b-form-group>
      <b-form-group
        class="mt-1"
      >
        <label>Kiểm tra</label>
        <b-form-input
          v-model="dataInput.exam"
          type="number"
        />
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      index: {
        required: 'Số thứ tự là bắt buộc',
      },
      content: {
        required: 'Nội dung là bắt buộc',
      },
      dataCombobox: [],
      dataInput: {
        index: '',
        content: '',
        theory: '',
        practice: '',
        exam: '',
      },
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          index: this.dataDetail.index,
          content: this.dataDetail.content,
          theory: this.dataDetail.theory,
          practice: this.dataDetail.practice,
          exam: this.dataDetail.exam,
        }
      }
    },
  },
  methods: {

    // Tạo đôi tượng kiểm định
    handleCreateObject(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateObject', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {}
    },
  },
}
</script>

<template>
  <div id="condition-training">
    <my-tabs
      :listTabs="listTabs"
      defaultActive="actions"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div>
      <actions
        v-if="isRendered('actions')"
        class="tab-pane page-container"
        :statusDisabled="isDisable"
        :class="{'active':activeTab==='actions'}"
        :dataTraining="dataTraining"
        @reload="reload"
        @hanldeDelete="deleteAction"
      />
      <test-object
        v-if="isRendered('coaching-program')"
        :conditionTrainingId="conditionTrainingId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='coaching-program'}"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import MyTabs from '@/components/tabs/MyTabs.vue'
import Actions from './components/Actions.vue'
import TestObject from './components/TestObject.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    MyTabs,
    Actions,
    TestObject,
    Document,
  },
  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: 'actions',
          title: 'Hành động',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'coaching-program',
          title: 'Chương trình khung huấn luyện nhóm 5',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },

      ],
      isDisable: false,
      conditionTrainingId: '',
      dataTraining: {},
      isRender: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  async created() {
    await this.fetchDataBusiness()
    if (this.activeTab === null) {
      this.activeTab = 'actions'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    async fetchDataBusiness() {
      const data = await axiosApiInstance.get(ConstantsApi.GET_INFO_DECLARE_TRAINING)
      if (data.status === 204) {
        this.listTabs[1].isDisabled = true
        this.isDisable = true
      }
      if (data.status === 200) {
        this.conditionTrainingId = data?.data?.id
        this.listTabs[1].isDisabled = false
        this.isDisable = false
        this.dataTraining = data?.data
      }
    },

    async  deleteAction() {
      this.$showAllPageLoading()
      const array = [this.conditionTrainingId]
      const res = await axiosApiInstance.post(ConstantsApi.DELETE_DECLARE_TRAINING, array)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataBusiness()
        this.listTabs[1].isRendered = false
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    async reload() {
      await this.fetchDataBusiness()
    },
  },
}
</script>
